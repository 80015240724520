import React from 'react'
import thumbnail from '../../assets/thumbnail.jpg'
import thumbnail2 from '../../assets/thumbnail2.jpg'
import './News.css'
import { useNavigate } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function News() {
    const articles = [{"Image": thumbnail,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24",
                       "Link": "4312"},
                      {"Image": thumbnail2,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail2,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail2,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail2,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"},
                       {"Image": thumbnail,
                       "Title": "Are Bella Porch and Johnny Gilbert Really Dating?",
                       "Description": "Youtuber Johnny Gilbert is under fire for dating the controversial Tiktoker.",
                       "Author": "Sydney Rey",
                       "Date": "3/8/24"}]
    const navigate = useNavigate();

    return (
        <div className="news-grid">
            {articles.map((article, index) => (
                <div key={index} className="news-card" onClick={() => navigate(`/p/${article.Link}`)}>
                    <img src={article.Image} alt="Thumbnail" className="news-thumbnail" />
                    <div className="news-content">
                        <h3 className="news-title">{article.Title}</h3>
                        {/* <p className="news-description">{article.Description}</p> */}
                        <div className='post-meta'>
                            <p className="news-author">By: {article.Author}</p>
                            <p className="news-date">
                                <CalendarMonthIcon />
                                &nbsp;&nbsp;
                                {article.Date}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default News;