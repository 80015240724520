import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function Home() {
  return (
    <div>
      <Header />
      <div className='home-wrapper'>
        <div className='home-content'>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
