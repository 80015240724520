import Router from './routes';
import './App.css';
import './glitter.css';
import sparkle1 from './assets/sparkle1.png';
import sparkle2 from './assets/sparkle2.png';
import sparkle3 from './assets/sparkle3.png';

function addGlitter() {
    const glitterContainer = document.createElement('div');
    glitterContainer.id = 'glitter-container';
    glitterContainer.style.position = 'fixed'; // Changed from 'absolute' to 'fixed'
    glitterContainer.style.top = '0';
    glitterContainer.style.left = '0';
    glitterContainer.style.width = '100%';
    glitterContainer.style.height = '100vh'; // Changed to '100vh' to cover the viewport height
    glitterContainer.style.zIndex = '-1'; // Set to a lower value to keep it in the background
    document.body.appendChild(glitterContainer);

    const glitterCount = 500; // Adjust the number of glitters as needed
    const glitterImages = [sparkle1, sparkle2, sparkle3];

    for (let i = 0; i < glitterCount; i++) {
        setTimeout(() => { // Delay the appearance of each glitter element
            const glitter = document.createElement('img');
            const randomImage = glitterImages[Math.floor(Math.random() * glitterImages.length)];
            glitter.src = randomImage;
            glitter.classList.add('glitter');
            glitter.style.width = '4%'; // Reduce the size to 20%
            glitter.style.height = 'auto'; // Maintain the aspect ratio

            // Adjusted to prevent spawning below the screen
            const x = Math.random() * (window.innerWidth - glitter.offsetWidth);
            const y = Math.random() * (document.documentElement.scrollHeight - glitter.offsetHeight);
            glitter.style.left = `${x}px`;
            glitter.style.top = `${y}px`;

            glitterContainer.appendChild(glitter);
        }, Math.random() * 3000); // Random delay up to 1 second
    }
}

function App() {
    addGlitter(); // Call the function to add the glitter effect

    return (
        <Router />
    );
}

export default App;
