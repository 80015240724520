import { useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import News from './pages/News/News';
import ArticlePage from './pages/News/Article/ArticlePage';
// layouts
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
      children: [
        { path: 'news', 
          element: <News />},
        {path: 'p', element: <ArticlePage />,
          children:[
          { path: ':postID'}
          ]}
      ]
    },
    // {
    //   path: '/reset',
    //   element: <ResetPwPage />,
    // },
    // {
    //   path: '/register',
    //   element: <RegisterPage />,
    // },
    //  {
    //   path: '/apply',
    //   element: <ApplyPage />,
    // },
    // {
    //   path: '/dashboard',
    //   element: 
    //     <IdleChecker>
    //       <DashboardLayout />
    //     </IdleChecker>,
    //   children: [
    //     { element: <Navigate to="/dashboard/stats" />, index: true },
    //     { path: 'stats', element: <DashboardAppPage /> },
    //     { path: 'sales', element: <SalesPage /> },
    //     { path: 'users', element: <Userpage /> },
    //     { path: 'contract', element: <MenteeContract /> },
    //     { path: 'menteecurriculum', element: <ProductsPage /> },
    //     { path: 'viewers', element: <Viewers /> },
    //     { path: 'help', element: <Help /> },
    //     { path: 'zoom', element: <Zoompage /> },
    //     { path: 'states', element: <Mainpage />, children: [
    //         { path: ':stateID', children: [
    //           { path: 'regions', children: [
    //               { path: ':regionID', children: [
    //                 { path: 'offices', children: [
    //                   { path: ':officeID', children: [
    //                     { path: 'mentors', children: [
    //                       { path: ':mentorID', children: [
    //                         { path: 'mentees'}
    //                       ] }
    //                     ] }
    //                   ] }
    //                 ] }
    //               ] }
    //             ]
    //           }
    //         ] }
    //       ]
    //     },
    //     // { path: 'products', element: <ProductsPage /> },
    //     // { path: 'blog', element: <BlogPage /> },
    //   ],
    // },
    // {
    //   path: '*',
    //   element: <Page404 />,
    // },
  ]);

  return routes;
}
