import React from 'react';
import logo from '../assets/logo.png';
import './Home.css';
import { useNavigate, useLocation } from 'react-router-dom';

function Header() {
    const topItems = ["news", "quizzes", "polls", "shop"];
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='top-bar'>
            <div className='top-bar-content'>
                <img className='top-logo' src={logo} onClick={() => navigate('/news')} alt="Logo"></img>
                    <div className='top-menu'>
                        {topItems.map((item, index) => {
                            const isActive = location.pathname === `/${item}`;
                            return (
                                <div 
                                    key={index}
                                    className={`top-menu-item ${isActive ? 'active' : ''}`} 
                                    onClick={() => navigate(`/${item}`)}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
            </div>
            {/* <img className='top-logo' src={logo} alt="Logo"></img> */}
        </div>
    );
}

export default Header;
