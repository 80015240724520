import React from 'react';
import logo from '../assets/logo.png';
import './Home.css';
import { useNavigate, useLocation } from 'react-router-dom';

function Footer() {
    const topItems = ["News", "Quizzes", "Polls", "Shop"];
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='ft-bar'>
            <div className='ft-bar-content'>
                <img className='top-logo' src={logo} alt="Logo"></img>
                    <div className='top-menu'>
                        {topItems.map((item, index) => {
                            const isActive = location.pathname === `/${item}`;
                            return (
                                <div 
                                    key={index}
                                    className={`top-menu-item ${isActive ? 'active' : ''}`} 
                                    style={index === 0 ? {borderLeftStyle: "solid"} : {}}
                                    onClick={() => navigate(`/${item}`)}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
            </div>
            {/* <img className='top-logo' src={logo} alt="Logo"></img> */}
        </div>
    );
}

export default Footer;
