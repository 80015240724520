import React from 'react';
import { useParams } from 'react-router-dom';
import './ArticlePage.css';
import thumbnail from '../../../assets/thumbnail.jpg'

function ArticlePage() {
  const { postID } = useParams();
  const articleContent = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consequat ac felis donec et odio pellentesque. Ullamcorper eget nulla facilisi etiam dignissim. Nisi quis eleifend quam adipiscing vitae proin sagittis. Diam in arcu cursus euismod quis viverra nibh cras. Sit amet risus nullam eget felis eget nunc lobortis mattis. Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Dui nunc mattis enim ut tellus elementum sagittis vitae et. Nibh tellus molestie nunc non blandit massa. Lacinia at quis risus sed vulputate. Nibh cras pulvinar mattis nunc. Mauris a diam maecenas sed enim ut sem viverra.

Arcu felis bibendum ut tristique et egestas. Ipsum suspendisse ultrices gravida dictum fusce ut placerat. Dignissim convallis aenean et tortor at. Et tortor consequat id porta nibh venenatis cras. Sit amet nulla facilisi morbi. Porttitor rhoncus dolor purus non enim praesent. Ornare lectus sit amet est placerat in. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum. Aliquet enim tortor at auctor urna. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Pulvinar proin gravida hendrerit lectus. Sed tempus urna et pharetra pharetra massa. Turpis massa sed elementum tempus egestas sed sed. Nullam eget felis eget nunc lobortis mattis aliquam. Cursus eget nunc scelerisque viverra mauris in aliquam sem. At volutpat diam ut venenatis tellus in metus vulputate eu.

Proin libero nunc consequat interdum. Aliquam purus sit amet luctus venenatis lectus magna. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Magnis dis parturient montes nascetur ridiculus mus. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Ut tellus elementum sagittis vitae et. Pulvinar etiam non quam lacus suspendisse faucibus. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Risus viverra adipiscing at in tellus. Nibh mauris cursus mattis molestie a iaculis at. Vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra. Nulla posuere sollicitudin aliquam ultrices sagittis. Nunc eget lorem dolor sed viverra ipsum nunc aliquet bibendum. Tristique nulla aliquet enim tortor at auctor urna. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Egestas erat imperdiet sed euismod. Vitae elementum curabitur vitae nunc sed velit dignissim. Tristique senectus et netus et.

Aliquet enim tortor at auctor urna nunc. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis. Quis varius quam quisque id diam vel quam elementum. Venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam. Lectus nulla at volutpat diam. Ultricies leo integer malesuada nunc vel risus commodo. Lorem ipsum dolor sit amet consectetur adipiscing elit. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Sed pulvinar proin gravida hendrerit lectus a. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Morbi leo urna molestie at elementum eu facilisis. Massa sed elementum tempus egestas sed sed risus pretium quam. Odio tempor orci dapibus ultrices in iaculis nunc sed augue. Tempus imperdiet nulla malesuada pellentesque elit eget. Justo laoreet sit amet cursus sit amet. Sed turpis tincidunt id aliquet risus feugiat in ante. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At consectetur lorem donec massa sapien faucibus et. Vitae tempus quam pellentesque nec.

Ridiculus mus mauris vitae ultricies. Sed arcu non odio euismod. Amet commodo nulla facilisi nullam vehicula ipsum. Sagittis vitae et leo duis ut. Risus quis varius quam quisque. Scelerisque eleifend donec pretium vulputate sapien. Lobortis elementum nibh tellus molestie nunc non blandit massa enim. Enim lobortis scelerisque fermentum dui faucibus in. In iaculis nunc sed augue lacus viverra vitae congue. Quam nulla porttitor massa id neque aliquam. Tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus. Ac felis donec et odio pellentesque diam volutpat. Urna et pharetra pharetra massa massa ultricies mi quis. Integer malesuada nunc vel risus. Dolor morbi non arcu risus quis varius. Lacus sed viverra tellus in hac habitasse. Integer enim neque volutpat ac. Hendrerit gravida rutrum quisque non tellus orci ac.`
  return (
    <div className='a-wrapper'>
      <div className='a-article'>
        <h1>Are Bella Porch and Johnny Gilbert Really Dating?</h1>
        <div className='a-thumbnail-wrapper'>
          <img className='a-thumbnail' alt="Thumbnail" src = {thumbnail} />
          <div className='a-thumb-src'>
            Jerritt Clark/Thaddaeus McAdams/Getty Images
          </div>
        </div>
        <div className='a-content'>
          {articleContent}
        </div>
      </div>
      <div className='a-sidebar'>

      </div>
    </div>
  );
}

export default ArticlePage;
